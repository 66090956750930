import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  svgHover: {
    fill: theme.palette.foreground.default,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
    transition: "all 0.5s ease",
  },
}));

export const Logo = () => {
  const classes = useStyles();

  return (
<svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="52"
      viewBox="0 0 495.398 495.398"
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
    >
      <path
        fill="#d7d8d8"
        d="M235.5.5v-1h33a208.122 208.122 0 0123 3 1235.029 1235.029 0 00-56-2z"
        opacity="0.506"
      ></path>
      <path
        fill="#2d2d2d"
        d="M268.5-.5h23c50.717 7.785 95.384 28.452 134 62a11.741 11.741 0 003 5c-29.07-25.54-62.07-44.374-99-56.5a314.999 314.999 0 00-24-5.5c-2.773-1.157-5.773-1.824-9-2h-5a208.122 208.122 0 00-23-3z"
        opacity="0.027"
      ></path>
      <path
        fill="#c5c6c5"
        d="M231.5.5a345.257 345.257 0 01-31 5c10.001-2.86 20.335-4.527 31-5z"
        opacity="0.576"
      ></path>
      <path
        fill="#fefefe"
        d="M231.5.5h4c18.684.243 37.351.91 56 2h5c2.671 1.618 5.671 2.285 9 2a314.999 314.999 0 0124 5.5c36.93 12.126 69.93 30.96 99 56.5l5 6c.667 2 2 3.333 4 4 20.237 19.587 36.237 42.254 48 68 .337 4.336 2.004 8.003 5 11 1.903 3.03 3.237 6.363 4 10a70.523 70.523 0 001.5 9 137.843 137.843 0 007.5 19 196.925 196.925 0 002 15.5c.025 1.876.691 3.376 2 4.5v1a496.061 496.061 0 011 76v2c-1.582 3.376-2.249 7.042-2 11v1a2222.488 2222.488 0 00-6.5 20 31.284 31.284 0 00-1.5 10 192.191 192.191 0 01-13.5 34 701.465 701.465 0 01-15.5 27c-1.406.473-2.073 1.473-2 3a124.016 124.016 0 00-11 15c-18.462 22.46-39.795 41.793-64 58-17.542 11.936-36.542 20.936-57 27-2.301.56-4.634 1.06-7 1.5-.556.383-.889.883-1 1.5h-2c-4.335.417-8.668.917-13 1.5-2.674.67-5.007 1.837-7 3.5a456.169 456.169 0 01-41 3 840.657 840.657 0 01-48-1h-1c-4.705-1.822-9.705-2.822-15-3-5.603-.715-10.936-2.048-16-4-2.338-1.54-5.005-2.206-8-2a126.507 126.507 0 01-12-4c-1.676-2.334-4.009-3.334-7-3a126.602 126.602 0 00-12-4c-59.968-30.148-102.968-76.148-129-138-.027-3.416-1.027-6.416-3-9a100.338 100.338 0 01-4-13c-.247-3.354-1.247-6.354-3-9a172.057 172.057 0 00-1.5-12c-.417-1.256-1.25-1.923-2.5-2-.903-2.891-1.236-5.891-1-9 .284-1.915-.049-3.581-1-5 0-.667-.333-1-1-1v-56a528.623 528.623 0 007-42c.938-6.779 2.938-13.112 6-19 2.422-4.665 4.09-9.665 5-15 12.193-30.157 29.526-56.824 52-80 4.94-3.27 9.273-7.27 13-12 33.385-31.362 72.718-51.696 118-61a345.257 345.257 0 0031-5z"
        opacity="0.997"
      ></path>
      <path
        fill="#949494"
        d="M296.5 2.5c3.227.176 6.227.843 9 2-3.329.285-6.329-.382-9-2z"
        opacity="0.184"
      ></path>
      <path
        fill="#060706"
        d="M452.5 17.5c1.333.667 1.333.667 0 0zM36.5 69.5c1.333.667 1.333.667 0 0z"
        opacity="0.004"
      ></path>
      <path
        fill="#c2c3c2"
        d="M82.5 66.5c-3.727 4.73-8.06 8.73-13 12 3.727-4.73 8.06-8.73 13-12z"
        opacity="0.533"
      ></path>
      <path
        fill="#a9a9a9"
        d="M433.5 72.5c2.333.333 3.667 1.667 4 4-.333-2.333-1.667-3.667-4-4z"
        opacity="0.369"
      ></path>
      <path
        fill="#202020"
        d="M425.5 61.5c47.556 43.108 76.223 96.442 86 160v66c-5.057 37.123-17.223 71.79-36.5 104a252.939 252.939 0 01-19.5 26l1-4a124.016 124.016 0 0111-15c1.406-.473 2.073-1.473 2-3a701.465 701.465 0 0015.5-27 192.191 192.191 0 0013.5-34c3.58-9.648 6.247-19.648 8-30v-1c1.187-3.446 1.854-7.113 2-11v-2c1.543-11.96 2.209-24.126 2-36.5l-.5-26.5a26.67 26.67 0 00-1.5-6.5l1-1a61.16 61.16 0 00-2-5.5v-1c-.489-6.957-1.822-13.624-4-20-1.828-9.815-4.828-19.148-9-28-.763-3.637-2.097-6.97-4-10-.882-4.093-2.549-7.76-5-11-11.763-25.746-27.763-48.413-48-68-.333-2.333-1.667-3.667-4-4l-5-6a11.741 11.741 0 01-3-5z"
        opacity="0.059"
      ></path>
      <path
        fill="#b6b7b6"
        d="M485.5 144.5c2.451 3.24 4.118 6.907 5 11-2.996-2.997-4.663-6.664-5-11z"
        opacity="0.576"
      ></path>
      <path
        fill="#8e8e8f"
        d="M421.5 157.5c-43.686-.307-87.353.027-131 1 .418-.722 1.084-1.222 2-1.5 43.169-.831 86.169-.665 129 .5z"
      ></path>
      <path
        fill="#515151"
        d="M240.5 157.5l2 3-6-1.5a2452.222 2452.222 0 00-117 .5c3-.593 6-1.426 9-2.5a2094.75 2094.75 0 01112 .5z"
      ></path>
      <path
        fill="#babcbc"
        d="M240.5 157.5c1.871.359 3.371 1.359 4.5 3 .805 3.196.638 6.196-.5 9 .306-2.885-.027-5.552-1-8 0-.667-.333-1-1-1l-2-3z"
      ></path>
      <path
        fill="#060606"
        d="M242.5 160.5c.667 0 1 .333 1 1 .686 3.787-.314 6.954-3 9.5l-116 1c-3.184.754-5.851 2.254-8 4.5h-1c-8.045 4.379-13.045 11.045-15 20-1.153 5.819-1.32 11.819-.5 18 .2 1.82 1.034 3.153 2.5 4 1.588 3.258 3.921 5.925 7 8 1.58 4.163 4.58 6.496 9 7 1.903.296 3.57.963 5 2 .111.617.444 1.117 1 1.5 6.658.5 13.325.666 20 .5.958.453 1.792 1.119 2.5 2 .497 2.31.663 4.643.5 7a802.777 802.777 0 01-5 4c-9.1.381-18.1-.285-27-2-4.551-1.461-8.551-3.794-12-7-.473-1.406-1.473-2.073-3-2a6.544 6.544 0 00-3-3 47.849 47.849 0 01-9-14c-1.157-2.773-1.824-5.773-2-9v-22c1.24-2.95 2.574-5.95 4-9a29.516 29.516 0 013-5c4.414-5.749 9.747-10.416 16-14 3.957.011 7.29-1.322 10-4a2452.222 2452.222 0 01117-.5l6 1.5z"
      ></path>
      <path
        fill="#050505"
        d="M421.5 157.5c1.639.973 2.973 2.306 4 4 .478 3.838-.522 7.171-3 10-42.001-.167-84.001 0-126 .5-3.092-.107-5.425 1.059-7 3.5-3 1-5 3-6 6-.617.111-1.117.444-1.5 1l-.5 61.5c0 20.844.667 41.344 2 61.5 1.667 3.667 4.333 6.333 8 8 .111.617.444 1.117 1 1.5 43.332.5 86.665.667 130 .5 4.351 3.438 5.017 7.438 2 12a4425.31 4425.31 0 01-132 1c-2.461-1.031-5.127-1.698-8-2-1.534-1.869-3.534-3.202-6-4-2-2.667-4.333-5-7-7a592.183 592.183 0 00-3-8v-125a150.975 150.975 0 005-13 38.65 38.65 0 009-8c2.897-.629 5.564-1.629 8-3a4468.602 4468.602 0 01131-1z"
      ></path>
      <path
        fill="#8b8d8e"
        d="M290.5 158.5c-2.436 1.371-5.103 2.371-8 3 2.117-2.155 4.784-3.155 8-3z"
      ></path>
      <path
        fill="#b7b8b7"
        d="M17.5 158.5c-.91 5.335-2.578 10.335-5 15 .694-5.415 2.36-10.415 5-15z"
        opacity="0.384"
      ></path>
      <path
        fill="#343434"
        d="M119.5 159.5c-2.71 2.678-6.043 4.011-10 4v-1c3.074-1.802 6.408-2.802 10-3z"
      ></path>
      <path
        fill="#6a6b6c"
        d="M282.5 161.5a38.65 38.65 0 01-9 8c-.086-.996.248-1.829 1-2.5a34.155 34.155 0 018-5.5z"
      ></path>
      <path
        fill="#858687"
        d="M109.5 162.5v1c-6.253 3.584-11.586 8.251-16 14 3.426-7.095 8.759-12.095 16-15z"
      ></path>
      <path
        fill="#272727"
        d="M217.5-.5h18v1h-4c-10.665.473-20.999 2.14-31 5-45.282 9.304-84.615 29.638-118 61-4.94 3.27-9.273 7.27-13 12-22.474 23.176-39.807 49.843-52 80-2.64 4.585-4.306 9.585-5 15-3.062 5.888-5.062 12.221-6 19a46.758 46.758 0 00-3 8c1.37-11.321 3.87-22.654 7.5-34C37.972 98.195 84.805 48.695 151.5 18c21.516-8.375 43.516-14.542 66-18.5z"
        opacity="0.031"
      ></path>
      <path
        fill="#727475"
        d="M425.5 161.5c1.941 1.511 2.441 3.511 1.5 6-.748 2.251-2.248 3.584-4.5 4 2.478-2.829 3.478-6.162 3-10z"
      ></path>
      <path
        fill="#565758"
        d="M243.5 161.5c.973 2.448 1.306 5.115 1 8l-4 3c-37.668-.167-75.335 0-113 .5-1.558.21-2.891.71-4 1.5l-7 2c2.149-2.246 4.816-3.746 8-4.5l116-1c2.686-2.546 3.686-5.713 3-9.5z"
      ></path>
      <path
        fill="#828485"
        d="M422.5 171.5c-1.43 1.037-3.097 1.704-5 2-41.668-.167-83.335 0-125 .5-.556.383-.889.883-1 1.5h-2c1.575-2.441 3.908-3.607 7-3.5 41.999-.5 83.999-.667 126-.5z"
      ></path>
      <path
        fill="#cccdce"
        d="M240.5 172.5c-.111.617-.444 1.117-1 1.5-38.665.5-77.332.667-116 .5 1.109-.79 2.442-1.29 4-1.5 37.665-.5 75.332-.667 113-.5z"
      ></path>
      <path
        fill="#e2e2e3"
        d="M417.5 173.5c-41.873.677-83.873 1.344-126 2 .111-.617.444-1.117 1-1.5 41.665-.5 83.332-.667 125-.5z"
      ></path>
      <path
        fill="#b4b5b5"
        d="M494.5 165.5c4.172 8.852 7.172 18.185 9 28a137.843 137.843 0 01-7.5-19 70.523 70.523 0 01-1.5-9z"
        opacity="0.533"
      ></path>
      <path
        fill="#5e5f5f"
        d="M273.5 169.5a150.975 150.975 0 01-5 13h-1v-1c.683-4.752 2.683-8.752 6-12z"
      ></path>
      <path fill="#818283" d="M289.5 175.5l-3 4-3 2c1-3 3-5 6-6z"></path>
      <path
        fill="#8e9091"
        d="M90.5 182.5c-1.426 3.05-2.76 6.05-4 9-.364-3.909.97-6.909 4-9z"
      ></path>
      <path
        fill="#d2d4d4"
        d="M286.5 179.5c-1.466 2.747-2.299 5.747-2.5 9-.5 37.332-.667 74.665-.5 112a1783.095 1783.095 0 010-119l3-2z"
      ></path>
      <path
        fill="#abadae"
        d="M267.5 181.5v1a3818.507 3818.507 0 00-1 122 2443.78 2443.78 0 01-.5-121c.278-.916.778-1.582 1.5-2z"
      ></path>
      <path
        fill="#acaeae"
        d="M115.5 176.5a76.67 76.67 0 00-11.5 14c-2.178 6.136-3.012 12.469-2.5 19-.985-4.134-1.318-8.468-1-13 1.955-8.955 6.955-15.621 15-20z"
      ></path>
      <path
        fill="#373737"
        d="M267.5 182.5h1v125l-2-3c-.316-40.697.017-81.364 1-122z"
      ></path>
      <path
        fill="#797a79"
        d="M283.5 181.5a1783.095 1783.095 0 000 119v5c-1.333-20.156-2-40.656-2-61.5l.5-61.5c.383-.556.883-.889 1.5-1z"
      ></path>
      <path
        fill="#151515"
        d="M3.5 200.5c.284 1.915-.049 3.581-1 5-.438.065-.772-.101-1-.5a36.278 36.278 0 012-4.5z"
        opacity="0.034"
      ></path>
      <path
        fill="#bcbebe"
        d="M85.5 193.5a60.24 60.24 0 000 20c-1.32-2.928-1.986-6.261-2-10 .014-3.739.68-7.072 2-10z"
      ></path>
      <path
        fill="#727373"
        d="M100.5 196.5c-.318 4.532.015 8.866 1 13a29.23 29.23 0 001 9c-1.466-.847-2.3-2.18-2.5-4-.82-6.181-.653-12.181.5-18z"
      ></path>
      <path
        fill="#aaa"
        d="M503.5 193.5c2.178 6.376 3.511 13.043 4 20-1.309-1.124-1.975-2.624-2-4.5a196.925 196.925 0 01-2-15.5z"
        opacity="0.459"
      ></path>
      <path
        fill="#404041"
        d="M86.5 191.5v22h-1a60.24 60.24 0 010-20c-.172-.992.162-1.658 1-2z"
      ></path>
      <path
        fill="#141414"
        d="M1.5 213.5c-.253 3.54-.92 6.873-2 10v-7l2-3z"
        opacity="0.004"
      ></path>
      <path
        fill="#c7c8c7"
        d="M6.5 192.5a528.623 528.623 0 01-7 42v-11c1.08-3.127 1.747-6.46 2-10-.221-2.779.112-5.446 1-8 .951-1.419 1.284-3.085 1-5a46.758 46.758 0 013-8z"
        opacity="0.48"
      ></path>
      <path
        fill="#a8aaaa"
        d="M88.5 222.5a47.849 47.849 0 009 14 2968.346 2968.346 0 01-7-6 17.768 17.768 0 01-2-8z"
      ></path>
      <path
        fill="#898a8b"
        d="M109.5 226.5a70.717 70.717 0 019 7c-4.42-.504-7.42-2.837-9-7z"
      ></path>
      <path
        fill="silver"
        d="M507.5 214.5a61.16 61.16 0 012 5.5l-1 1a26.67 26.67 0 011.5 6.5l.5 26.5c.209 12.374-.457 24.54-2 36.5a496.061 496.061 0 00-1-76z"
        opacity="0.133"
      ></path>
      <path
        fill="#555657"
        d="M123.5 235.5c5.676-.166 11.343.001 17 .5 1.558.21 2.891.71 4 1.5-6.675.166-13.342 0-20-.5-.556-.383-.889-.883-1-1.5z"
      ></path>
      <path
        fill="#070707"
        d="M205.5 237.5a81.802 81.802 0 0110 2 88.365 88.365 0 0111 6 25.658 25.658 0 006 6 25.497 25.497 0 015 7c1.646 4.654 3.646 8.987 6 13h1v18c.237 1.291-.096 2.291-1 3-1.446 2.754-2.446 5.754-3 9 .098 2.053-.569 3.72-2 5-2.731 1.571-4.565 3.905-5.5 7a111.17 111.17 0 01-12.5 11 8.43 8.43 0 00-4 .5c-2.258.876-4.258 2.043-6 3.5a2539.256 2539.256 0 01-122 .5c-.916-.278-1.582-.778-2-1.5-2.03-4.666-1.03-8.499 3-11.5l115-1a38.715 38.715 0 005-2.5 5.727 5.727 0 013-1 8.43 8.43 0 004-.5 16.952 16.952 0 005-6.5c2.322-1.302 3.655-3.302 4-6 2.106-2.219 3.106-4.885 3-8 1.547-2.307 2.213-4.973 2-8 .102-3.617-.565-6.95-2-10h-1c-.325-4.64-2.325-8.306-6-11-1-.333-1.667-1-2-2-1.085-2.087-2.751-3.42-5-4a1972.796 1972.796 0 00-8-3.5 216.796 216.796 0 01-21-1.5c-3.953-4.169-3.953-8.335 0-12.5 6.658-.5 13.325-.666 20-.5z"
      ></path>
      <path
        fill="#616262"
        d="M100.5 239.5c1.527-.073 2.527.594 3 2-1.527.073-2.527-.594-3-2z"
      ></path>
      <path
        fill="#bcbebe"
        d="M215.5 239.5c5.197-.484 8.864 1.516 11 6a88.365 88.365 0 00-11-6z"
      ></path>
      <path
        fill="#757677"
        d="M205.5 237.5c-6.675-.166-13.342 0-20 .5-3.953 4.165-3.953 8.331 0 12.5-3.397-.758-4.73-2.925-4-6.5.28-6.975 3.947-9.809 11-8.5 4.593-.121 8.926.546 13 2z"
      ></path>
      <path
        fill="#a9aaab"
        d="M226.5 245.5c3 1 5 3 6 6a25.658 25.658 0 01-6-6z"
      ></path>
      <path
        fill="#797b7c"
        d="M147.5 246.5c-.467 2.797-2.133 4.131-5 4a802.777 802.777 0 005-4z"
      ></path>
      <path
        fill="#797a7b"
        d="M115.5 248.5c8.9 1.715 17.9 2.381 27 2-6.821 1.155-13.821 1.321-21 .5-2.352-.24-4.352-1.073-6-2.5z"
      ></path>
      <path
        fill="#646565"
        d="M214.5 255.5c2.249.58 3.915 1.913 5 4-2.249-.58-3.915-1.913-5-4z"
      ></path>
      <path
        fill="#afb0b1"
        d="M221.5 261.5c3.675 2.694 5.675 6.36 6 11-1.46-2.478-3.293-4.811-5.5-7a8.43 8.43 0 01-.5-4z"
      ></path>
      <path
        fill="#5f6060"
        d="M237.5 258.5c3.711 3.426 5.711 7.759 6 13-2.354-4.013-4.354-8.346-6-13z"
      ></path>
      <path
        fill="#cecfd0"
        d="M244.5 271.5c1.403 3.572 2.069 7.405 2 11.5-.033 2.61-.7 4.777-2 6.5v-18z"
      ></path>
      <path
        fill="#818383"
        d="M228.5 272.5c1.435 3.05 2.102 6.383 2 10 .213 3.027-.453 5.693-2 8v-18z"
      ></path>
      <path
        fill="#191919"
        d="M-.5 291.5h1c.951 1.419 1.284 3.085 1 5l-2-3v-2z"
        opacity="0.031"
      ></path>
      <path
        fill="#616162"
        d="M243.5 292.5c.054 3.492-.946 6.492-3 9 .554-3.246 1.554-6.246 3-9z"
      ></path>
      <path
        fill="#8b8c8c"
        d="M508.5 292.5c-.146 3.887-.813 7.554-2 11-.249-3.958.418-7.624 2-11z"
        opacity="0.11"
      ></path>
      <path
        fill="#8d8f90"
        d="M225.5 298.5c-.345 2.698-1.678 4.698-4 6 .798-2.466 2.131-4.466 4-6z"
      ></path>
      <path
        fill="#8c8e8e"
        d="M283.5 305.5c2.641 2.304 5.308 4.638 8 7v1c-3.667-1.667-6.333-4.333-8-8z"
      ></path>
      <path
        fill="#404041"
        d="M221.5 304.5a16.952 16.952 0 01-5 6.5 8.43 8.43 0 01-4 .5 74.707 74.707 0 019-7z"
      ></path>
      <path
        fill="#868686"
        d="M2.5 305.5c1.25.077 2.083.744 2.5 2a172.057 172.057 0 011.5 12l-2-4a44.338 44.338 0 01-2-10z"
        opacity="0.247"
      ></path>
      <path
        fill="#dadbdb"
        d="M291.5 313.5v-1c43.982.807 87.982 1.307 132 1.5 2.148 2.457 3.815 5.124 5 8 .102 3.062-1.231 5.229-4 6.5v-1c3.017-4.562 2.351-8.562-2-12-43.539-.677-87.206-1.344-131-2z"
      ></path>
      <path
        fill="#7b7c7c"
        d="M209.5 312.5a38.715 38.715 0 01-5 2.5l-115 1c-4.03 3.001-5.03 6.834-3 11.5-3.066-4.613-2.4-8.78 2-12.5l111-1a52.016 52.016 0 0110-1.5z"
      ></path>
      <path
        fill="#767777"
        d="M291.5 313.5c43.794.656 87.461 1.323 131 2-43.335.167-86.668 0-130-.5-.556-.383-.889-.883-1-1.5z"
      ></path>
      <path
        fill="#898b8b"
        d="M238.5 306.5c-3.667 8.333-9.667 14.333-18 18a111.17 111.17 0 0012.5-11c.935-3.095 2.769-5.429 5.5-7z"
      ></path>
      <path
        fill="#999b9c"
        d="M271.5 315.5c2.667 2 5 4.333 7 7-3.333-1.333-5.667-3.667-7-7z"
      ></path>
      <path
        fill="#565657"
        d="M278.5 322.5c2.466.798 4.466 2.131 6 4-2.647-.429-4.647-1.762-6-4z"
      ></path>
      <path
        fill="#a9aaaa"
        d="M6.5 319.5c1.753 2.646 2.753 5.646 3 9-1.753-2.646-2.753-5.646-3-9z"
        opacity="0.369"
      ></path>
      <path
        fill="#aaabac"
        d="M220.5 324.5c-2.391 3.417-5.725 4.75-10 4 1.742-1.457 3.742-2.624 6-3.5a8.43 8.43 0 014-.5z"
      ></path>
      <path
        fill="#454545"
        d="M284.5 326.5c2.873.302 5.539.969 8 2h-4c-1.653-.16-2.986-.826-4-2z"
      ></path>
      <path
        fill="#acacac"
        d="M506.5 304.5c-1.753 10.352-4.42 20.352-8 30a31.284 31.284 0 011.5-10c2.209-6.903 4.375-13.57 6.5-20z"
        opacity="0.298"
      ></path>
      <path
        fill="#1a191a"
        d="M424.5 327.5v1l-2 1c-43.503.332-86.837-.001-130-1a4425.31 4425.31 0 00132-1z"
      ></path>
      <path
        fill="#909090"
        d="M288.5 328.5h4c43.163.999 86.497 1.332 130 1a2908.381 2908.381 0 01-132 .5c-.916-.278-1.582-.778-2-1.5z"
      ></path>
      <path
        fill="#acacac"
        d="M13.5 341.5c1.973 2.584 2.973 5.584 3 9-1.572-2.715-2.572-5.715-3-9z"
        opacity="0.247"
      ></path>
      <path
        fill="#060706"
        d="M508.5 360.5c1.333.667 1.333.667 0 0z"
        opacity="0.004"
      ></path>
      <path
        fill="#777877"
        d="M469.5 395.5c.073 1.527-.594 2.527-2 3-.073-1.527.594-2.527 2-3z"
        opacity="0.184"
      ></path>
      <path
        fill="#272727"
        d="M4.5 315.5l2 4c.247 3.354 1.247 6.354 3 9a100.338 100.338 0 004 13c.428 3.285 1.428 6.285 3 9 26.032 61.852 69.032 107.852 129 138C90.166 463.336 49 423.336 22 368.5A689.583 689.583 0 016.5 330l1-1c-2.334-4.174-3.334-8.674-3-13.5z"
        opacity="0.086"
      ></path>
      <path
        fill="#060706"
        d="M504.5 419.5c1.333.667 1.333.667 0 0z"
        opacity="0.004"
      ></path>
      <path
        fill="#2f2f2f"
        d="M456.5 413.5l-1 4c-15.876 20.882-34.876 38.215-57 52-2.185.204-4.185.87-6 2 24.205-16.207 45.538-35.54 64-58z"
        opacity="0.039"
      ></path>
      <path
        fill="#060706"
        d="M43.5 455.5c1.025.397 1.192 1.063.5 2a2.427 2.427 0 01-.5-2zM507.5 466.5c.397-1.025 1.063-1.192 2-.5a2.428 2.428 0 01-2 .5z"
        opacity="0.004"
      ></path>
      <path
        fill="#1f1f1f"
        d="M398.5 469.5c-19.548 13.754-40.881 24.087-64 31l1-2c20.458-6.064 39.458-15.064 57-27 1.815-1.13 3.815-1.796 6-2z"
        opacity="0.024"
      ></path>
      <path
        fill="#ababab"
        d="M157.5 492.5c2.991-.334 5.324.666 7 3-2.657-.435-4.991-1.435-7-3z"
        opacity="0.384"
      ></path>
      <path
        fill="#060706"
        d="M115.5 497.5c1.333.667 1.333.667 0 0z"
        opacity="0.004"
      ></path>
      <path
        fill="#acacac"
        d="M176.5 499.5c2.995-.206 5.662.46 8 2-2.995.206-5.662-.46-8-2z"
        opacity="0.459"
      ></path>
      <path
        fill="#585858"
        d="M335.5 498.5l-1 2a21.435 21.435 0 00-7 1c.111-.617.444-1.117 1-1.5 2.366-.44 4.699-.94 7-1.5z"
        opacity="0.059"
      ></path>
      <path
        fill="#c6c7c6"
        d="M325.5 501.5a132.853 132.853 0 01-20 5c1.993-1.663 4.326-2.83 7-3.5a345.467 345.467 0 0113-1.5z"
        opacity="0.48"
      ></path>
      <path
        fill="#1d1d1d"
        d="M145.5 488.5a126.602 126.602 0 0112 4c2.009 1.565 4.343 2.565 7 3a126.507 126.507 0 0012 4c2.338 1.54 5.005 2.206 8 2 5.064 1.952 10.397 3.285 16 4 4.73 1.737 9.73 2.737 15 3h1a563.884 563.884 0 0020 3h-15c-23.623-3.366-46.623-9.533-69-18.5-2.687-1.049-5.02-2.549-7-4.5z"
        opacity="0.027"
      ></path>
      <path
        fill="#1e1f1e"
        d="M334.5 500.5a238.08 238.08 0 01-46 11h-15a297.101 297.101 0 0032-5 132.853 132.853 0 0020-5h2a21.435 21.435 0 017-1z"
        opacity="0.031"
      ></path>
      <path
        fill="#babbbb"
        d="M200.5 505.5c5.295.178 10.295 1.178 15 3-5.27-.263-10.27-1.263-15-3z"
        opacity="0.384"
      ></path>
      <path
        fill="#d2d3d2"
        d="M305.5 506.5a297.101 297.101 0 01-32 5h-37a563.884 563.884 0 01-20-3 840.657 840.657 0 0048 1 456.169 456.169 0 0041-3z"
        opacity="0.314"
      ></path>
    </svg>
  );
};
