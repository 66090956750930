/* eslint-disable no-unused-vars */
import React from "react";
import { useState } from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "../content/TextDecrypt";

import "./Works.css";

// Import ../../assets/recentprojects/
import sofu from "../../assets/recentprojects/sofu.png";
import vgedits from "../../assets/recentprojects/vgedits.png";
import LaVisuals from "../../assets/recentprojects/lavisuals.png";
import Oxygen from "../../assets/recentprojects/oxygen.png";
import Mitthu from "../../assets/recentprojects/mitthu.png";

const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: "100vw",
    marginTop: "3em",
    marginBottom: "auto",
  },
}));

export const Works = () => {
  const classes = useStyles();
  const [projects, setProjects] = useState([
    {
      id: 1,
      title: "LaVisuals",
      description: `Crafed for LaVisuals Graphic Works with multiple Integrations and Ecommerce Support.
      Featuring Portfolios and Blogs of Various Artists in the Organisation. "Project Halted"`,
      alter: "Startup Project",
      image: `${LaVisuals}`,
      link: `https://i.kwin.in/r/Lavisuals.png`
    },
    {
      id: 2,
      title: "SOFU ☕",
      description: `An advanced JavaScript Discord bot designed to assist SOFI players,
      utilizing Optical Character Recognition (OCR) framework`,
      alter: "SOFU Discord",
      image: `${sofu}`,
      link: "https://discord.com/application-directory/950166445034188820"
    },

    {
      id: 3,
      title: "VG Edits",
      description: `Site Designed for Vansh Grover, Visual Animator and designer!
      Showcasing his Portfolio along with the Testimonials of Clients.`,
      alter: "VGEdits",
      image: `${vgedits}`,
      link: "https://vgedits.in"
    },
    {
      id: 4,
      title: "OxygenClasses",
      description: `Oxygen Classes crafted in order to showcase the skills of the teachers and the achievements of the students of Oxygen Classes.`,
      alter: "Oxygen",
      image: `${Oxygen}`,
      link: `https://oxygenclasses.com`
    },
    {
      id: 5,
      title: "Mitthu",
      description: `Seamlessly access and enjoy music from all sources with our powerful NodeJS music bot`,
      alter: "Mitthu",
      image: `${Mitthu}`,
      link: `https://discord.com/application-directory/1041470945417760879`
    },
  ]);

  return (
    <section id="works">
      <Container component="main" className={classes.main} maxWidth="md">
        {projects.map((project) => (
          <div className="project" key={project.id}>
            <div className="__img_wrapper">
              <a href={project.link} className="__img_wrapper" target="_blank" rel="noreferrer">
                <img src={project.image} alt={project.alter} />
              </a>
            </div>
            <div className="__content_wrapper">
              <h3 className="title">
                <TextDecrypt
                  text={project.id + ". " + project.title}
                  link={
                    "https://discord.com/servers/sofu-hub-1028673562212585605"
                  }
                />
              </h3>
              <p className="description">{project.description}</p>
            </div>
          </div>
        ))}
      </Container>
    </section>
  );
};
